import LocomotiveScroll from 'locomotive-scroll';
import { mapActions, mapState } from 'vuex';
import { html } from '../utils/environment';
import { lazyLoadImage } from '../utils/image';

const locomotiveScrollMixin = {
    computed: {
        ...mapState("main", ["isFontsLoaded", "scrollUpdate"]),
    },

    watch: {
        isFontsLoaded: "onFontsLoaded",
        scrollUpdate: "onScrollUpdate"
    },

    created() {
        this.onFontsLoadedBind = this.onFontsLoaded.bind(this)
        this.onCallBind = this.onCall.bind(this)
        this.onScrollBind = this.onScroll.bind(this)

        // Scroll data
        this.currentScrollY = 0
        this.lastScrollY = 0
    },

    mounted() {
        this.initScroll()
    },

    beforeUnmount() {
        this.destroyScroll()
    },

    methods: {
        ...mapActions('scroll', ['setWheelIncrement', 'setCurrentScroll', 'setSmoothState', 'setLimitScroll', 'setScrollTriggerProgress']),

        initScroll() {
            if (!this.$refs.scroll) return

            this.scroll = new LocomotiveScroll({
                el: this.$refs.scroll,
                smooth: true,
                offset: [0, 0]
            });

            this.$nextTick(() => {
                this.setCurrentScroll(0)
                this.scroll && this.setSmoothState(html.classList.contains('has-scroll-smooth'))
            })

            this.scroll.on('call', this.onCallBind);
            this.scroll.on('scroll', this.onScrollBind)
        },

        destroyScroll() {
            this.scroll?.off?.('call', this.onCallBind);
            this.scroll?.off?.('scroll', this.onScrollBind)
            this.scroll?.destroy?.();
        },

        updateScroll() {
            this.scroll?.update?.()
        },

        scrollTo(params) {
            this.scroll?.scrollTo?.(params.target, params.options);
        },

        lazyLoad(obj) {
            lazyLoadImage(obj.el, null, () => { })
        },

        onFontsLoaded() {
            this.scroll?.update?.();
        },

        onScroll(args) {
            // Data
            this.lastScrollY = this.currentScrollY
            this.currentScrollY = args.scroll.y
            const deltaScrollY = this.currentScrollY - this.lastScrollY

            // Scroll class
            if (args.scroll.y > 80) {
                html.classList.add('has-scrolled')
            } else {
                html.classList.remove('has-scrolled')
            }

            // Store current Y
            this.setCurrentScroll(this.currentScrollY)

            // Scroll wheel
            this.setWheelIncrement(deltaScrollY)

            // Limit scroll
            this.setLimitScroll(args.limit)

            // Scroll Trigger progress
            let scrollTriggerIndex = 0
            const elements = args.currentElements
            const keys = Object.keys(elements)

            while (scrollTriggerIndex < keys.length) {
                const currentKey = keys[scrollTriggerIndex]
                if (currentKey.includes('scrollTrigger-')) {
                    const obj = args.currentElements[currentKey]
                    const uid = currentKey.split("scrollTrigger-").pop()
                    this.setScrollTriggerProgress({
                        uid: uid,
                        progress: obj.progress
                    })
                }
                scrollTriggerIndex++
            }
        },

        onCall(func, way, obj) {
            switch (func) {
                case 'lazyLoad':
                    if (way === 'enter') {
                        this.lazyLoad(obj)
                    }
                    break
                case 'toggleWindow':
                    this.toggleWindowBackground(way, obj)
                    break
                case 'toggleHideWindow':
                    this.toggleHideWindow(way, obj)
                    break
            }
        },

        onScrollUpdate() {
            this.scroll?.update()
        },

        toggleWindowBackground(way) {
            if (way === "enter") {
                this.hasWindowBackground = true
            } else if (way === "exit") {
                this.hasWindowBackground = false
            }
        },

        toggleHideWindow(way) {
            console.log(way)
            if (way === "enter") {
                this.hideWindow = true
            } else if (way === "exit") {
                this.hideWindow = false
            }
        }
    }
}

export default locomotiveScrollMixin